






















import { Vue, Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import ResponseGenerico from "@/entidades/ResponseGenerico";
import {
  AprobacionRechazarModal,
  AprobarParte,
  RechazarParte,
} from "@/entidades/Plantacion/Partes/Consulta/ParteModelConsulta";
@Component({})
export default class extends Vue {
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  modal: boolean = false;
  motivo: string = "";
  parteId: number = 0;
  usuarioId: string = "";
  aprobacionId: number = 0;
  nivelActual: number = 0;
  nivelMaximo: number = 0;
  tipo: number = 0;

  async rechazar() {
    this.changeLoading(new Loading(true, "Rechazando parte"));
    try {
      var aproParte: AprobacionRechazarModal = new AprobacionRechazarModal(
        this.parteId,
        this.usuarioId,
        this.aprobacionId,
        false,
        this.motivo,
        this.nivelActual,
        this.nivelMaximo
      );
      var response = await RechazarParte(aproParte);
      console.log(response);
      if (response.isSuccess) {
        this.modal = false;
        this.changeAlerta(
          new Alerta("Aprobación exitosa", true, "success", 3000)
        );
        setTimeout(() => {
          this.$router.replace({ name: "Login" });
        }, 1000);
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async mounted() {
    // this.changeAlerta(new Alerta("Aprobación exitosa",false,"success",3000));
    var parteId = Number(this.$route.params.parteId);
    var aprobacionId = Number(this.$route.params.aprobacionId);
    var usuarioId = this.$route.params.usuarioId;
    var nivelActual = Number(this.$route.params.nivelActual);
    var nivelMaximo = Number(this.$route.params.nivelMaximo);
    var tipo = Number(this.$route.params.tipo);

    if (tipo == 1) {
      try {
        this.changeLoading(new Loading(true, "Aprobando parte"));
        var aproParte: AprobacionRechazarModal = new AprobacionRechazarModal(
          parteId,
          usuarioId,
          aprobacionId,
          true,
          "",
          nivelActual,
          nivelMaximo
        );

        let enviar: AprobacionRechazarModal[] = [];
        enviar.push(aproParte);
        var rsp = await AprobarParte(enviar);

        if (rsp.isSuccess) {
          this.changeAlerta(
            new Alerta("Aprobación exitosa", true, "success", 3000)
          );
          setTimeout(() => {
            this.$router.replace({ name: "Login" });
          }, 1000);
        } else {
          this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 3000));
        }
      } catch (error) {
      } finally {
        this.changeLoading(new Loading(false, "Aprobando parte"));
      }
    } else {
      this.modal = true;
      this.parteId = parteId;
      this.usuarioId = usuarioId;
      this.aprobacionId = aprobacionId;
      this.nivelActual = nivelActual;
      this.nivelMaximo = nivelMaximo;
      this.tipo = tipo;
    }
  }

  get validarFormulario() {
    if (this.motivo === "") return true;
    else return false;
  }
}
